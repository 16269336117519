import { render, staticRenderFns } from "./Normal.vue?vue&type=template&id=39068e96&scoped=true&"
import script from "./Normal.vue?vue&type=script&lang=js&"
export * from "./Normal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39068e96",
  null
  
)

export default component.exports